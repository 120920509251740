/* width */
.ai_modal_chat::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .ai_modal_chat::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .ai_modal_chat::-webkit-scrollbar-thumb {
    background: #0B0149; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .ai_modal_chat::-webkit-scrollbar-thumb:hover {
    background: #08003a; 
  }

  .rotate-scale-up {
    -webkit-animation: rotate-scale-up 4s linear 2s infinite both;
            animation: rotate-scale-up 4s linear 2s infinite both;
  }