.react-datalist-input__container > * {
    box-sizing: border-box;
  }
  
  .react-datalist-input__container {
    width: 100%;
    position: relative;
    --shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --gray: rgb(200, 200, 200);
    --padding: 5px;
    --border: 1px solid var(--gray);
  }
  
  .react-datalist-input__textbox {
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: var(--padding);
    /* border: var(--border); */
  }
  
  .react-datalist-input__listbox {
    width: 100%;
    position: absolute;
    margin: 2px 0 0 0;
    padding: 0;
    /* border-radius: 45px; */
    background-color: white;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    max-height: 150px;
    overflow-y: scroll;
    z-index: 10;
  }
  
  .react-datalist-input__listbox-option {
    width: 100%;
    cursor: pointer;
    margin: 0;
    padding: var(--padding);
  }
  
  .react-datalist-input__listbox-option:focus {
    background-color: var(--gray);
  }
  
  .react-datalist-input__listbox-option:hover {
    background-color: var(--gray);
  }
  